/* @font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf')
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./assets/fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}

/* * {
  font-family: "Montserrat";
} */

.form_input {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 16px;
}
.cursor-no-drop{
  cursor: no-drop !important;
  opacity: 0.3;
}
.cursor-pointer {
  cursor: pointer;
}
.mymain {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.bluediv {
  flex-grow: 1;
}

.cus-sider .ant-layout-sider-children {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ant-menu-flow{
  overflow: auto;
  flex-grow: 1;
}

.overflow-auto {
  overflow: auto;
}

.button_auth {
  width: 100%;
  height: 60px;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  background-color: #3f86c7;
  transition: all 0.2s ease-in-out;
}

.button_auth_border {
  width: 100%;
  height: 60px;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    border: 1.5px solid black;
    border-radius: 30px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.button_auth:hover {
    background-color: #30699b;
}

.button_auth:disabled {
    background-color: #dedede;
}

.link_auth {
  color:#30699b;
}

.link_auth:hover {
  text-transform: underline;
}

.auth_body {
    width: 600px;
    margin: auto;
}

.button_body {
    width: 364px;
}

a {
  text-decoration: none;
}

@media (max-width: 767px) {
  .auth_body {
    width: 100%;
    margin: auto;
  }

  .button_body {
    width: 100%;
  }
}

.text-start{
  font-size: 24px;
  /* padding-bottom: 20px; */
}

.formatInput .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 45px;
  margin-left: 0;
  border-radius: 5px;
  line-height: 25px;
  height: 40px !important;
  width: 100% !important;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  outline: none;
}

:where(.css-dev-only-do-not-override-apn68).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: lightgrey;
}

.notes_title {
  opacity: 0.8;
  color: rgba(21, 44, 91, 1);
  font-family: Montserrat;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: normal;
  letter-spacing: 0rem;
  text-align: center;
}

.esi-logo {  
  block-size: 40px;  
  inline-size: auto;  
  object-fit: cover;  
  background-blend-mode: inherit;
} 

.esi-logo54 {  
  block-size: 54px;  
  inline-size: auto;  
  object-fit: cover;  
  background-blend-mode: inherit;
  height: 42px;
  width: 42px;
} 

.text-wrap-nowrap {
  text-wrap-mode: nowrap;
}
.esi-logo16 {
  block-size: 12px;
  inline-size: auto;
  object-fit: cover;
  background-blend-mode: inherit;
}

:where(.css-dev-only-do-not-override-apn68).ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff00;
  border-color: hsla(0, 100%, 54%, 0);
  color: #e72158;
}
:where(.css-dev-only-do-not-override-apn68).ant-pagination .ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-inline-end: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #ffffff00;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
:where(.css-dev-only-do-not-override-apn68).ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.06);
}

html, body, #root {
  height: 100%;
}


/* sidebar */
:where(.css-dev-only-do-not-override-apn68).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-apn68).ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: hsl(344, 66%, 93%);
}
:where(.css-dev-only-do-not-override-apn68).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-apn68).ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: hsl(336, 100%, 44%);
  font-weight: 600;
}
.sidebar-cus-active .ant-menu-item-selected {
  color: hsl(336, 100%, 44%) !important;
  font-weight: 600 !important;
  background-color: hsl(344, 66%, 93%) !important;
}

.btn-sidebar {
  font-size: 12px;
  /* height: 64px; */
  position: absolute;
  right: -15px;
  z-index: 12;
  background: #fff;
  border-radius: 50%;
  margin-top: 13px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-sidebar:hover {
  font-size: 12px;
  /* height: 64px; */
  position: absolute;
  right: -15px;
  z-index: 99999;
  background: #fff !important;
  border-radius: 50%;
  margin-top: 13px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.btngoCreate {
  background-color: hsl(344, 66%, 93%);
  color: hsl(336, 100%, 44%);
  border: none !important;
}
.btngoCreate:hover {
  background-color: hsl(344, 66%, 93%) !important;
  color: hsl(336, 100%, 44%) !important;
  border: none !important;
}
.chart_milestone {
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0rem;
}
.titleDashbord{
  font-size: 14px;
  color: #636E88;
}
.totalDashboard{
  font-size: 28px;
  color:#222222;
  font-weight: 600;
}
.currencyDashbord{
  font-size: 16px;
  color: #636E88;
}

.ant-switch-checked{
  background-color: #46B988 !important;
}

.cusCard .ant-card-body {
  padding: 15px;
  border-radius: 0 0 8px 8px;
}

.newUpload .ant-upload .ant-upload-btn{
  background-color: #fff;
}

.multipleCus .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item{
  background-color: #fdecf1;
  color: #E50A47;
}
.multipleCus .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove{
  color: #E50A47;
  opacity: 0.5;
}
.uploadCoverCus .ant-upload-list-picture-card .ant-upload-select{
  height: 180px !important;
  width: 136px !important;
}
.uploadCoverCus .ant-upload-list-picture-card .ant-upload-list-item-container{
  height: 180px !important;
  width: 136px !important;
}
.cusCard .ant-card-body{
  padding:12px !important
}
.cusCard .ant-card-actions{
  border: none !important;
  padding-left: 10px;
}
.cusCard .ant-card-actions li{
  border-inline-end: none !important;
}
:where(.css-dev-only-do-not-override-apn68).ant-image-preview-root .ant-image-preview-wrap {
  position: fixed;
  inset: 0;
  z-index: 999999991 !important;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-image-preview-wrap {
  position: fixed;
  inset: 0;
  z-index: 999999991 !important;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
:where(.css-dev-only-do-not-override-apn68).ant-image-preview-root .ant-image-preview-mask {
  position: fixed;
  inset: 0;
  z-index: 999999990 !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-image-preview-mask {
  position: fixed;
  inset: 0;
  z-index: 999999990 !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
:where(.css-dev-only-do-not-override-apn68).ant-image-preview-operations-wrapper {
  position: fixed;
  z-index: 999999999 !important;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  z-index: 999999999 !important;
}

.textPermission {
  font-size: 12px;
  background-color: rgb(248, 226, 232);
  border: none;
  color: rgb(231, 33, 88);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border-radius: 6px;
  text-wrap-mode: nowrap;
}
.textTag {
  font-size: 12px;
  background-color: rgb(248, 226, 232);
  border: none;
  color: rgb(231, 33, 88);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border-radius: 6px;
  text-wrap-mode: nowrap;
}
.span-danger {
  color: #EB0004;
}

.uploadCoverCusNone .ant-upload-list-picture-card .ant-upload-select {
  display: none;
}

.uploadCoverError .ant-upload-list-picture-card .ant-upload-select {
  border: 1px dashed red !important;
}
.avatar-upload {
  text-decoration: underline;
  color: #0072F9;
}
.div-step {
  height: 26px;
  width: 26px;
  background: #fffffff2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D9DDDF;
  color: #222222;
  border-radius: 6px;
}
.div-step-active {
  background-color: rgb(229, 10, 71);
  color: rgb(255, 255, 255);
}

.ant-upload-wrapper-cus .ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgb(0 0 0 / 0%);
  /* background: rgb(0 0 0 / 2%); */
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  min-height: 117px;
}
.text-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  line-height: 29px;
}
.cursor-grab {
  cursor: grab;
}
.segmented-cus .ant-segmented-item-selected {
  background-color: #FDECF1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.88);
}

.active-under {
  background-color: #FDECF1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.88);
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
}


/* view mobile */
.div-phone {
  background-size: contain;
  background-position: center center;
  border-radius: 2rem;
  /* width: 350; */
  width: 375px;
  height: 750px;
  margin: auto;
  border: none;
  background-repeat: no-repeat;
}
.card-mobile {
  /* overflow: auto; */
  /* border-radius: 1rem; */
  height: 100%;
  /* max-height: 560px; */
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.title-content {
  text-align: center;
  /* position: absolute; */
  /* margin: auto; */
  width: 100%;
}
.image-mobile {
  width: 100%;
  height: 168px;
  border-radius: 6px;
  object-fit: cover;
}
.image-mobile-150 {
  width: 100%;
  height: 150px;
  border-radius: 6px;
  object-fit: cover;
}
.tile-new-mobile {
  font-weight: 500;
}
.subtile-new-mobile {
  font-size: 12px;
  opacity: 0.6;
}
.font-size-11 {
  font-size: 11px !important;
}
.content-dynamic-main {
  width: 100%;
  height: 100%;
  /* border: 1px solid #c3c3c3; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.content-dynamic-scroll {
  overflow: auto;
  flex-grow: 1;
}

.h1 {
  margin-bottom: 0px;
  /* font-weight: bold; */
  font-size: 24px;
  text-decoration: none;
}
.h2 {
  /* font-weight: bold; */
  font-size: 22px;
  margin-bottom: 0px;
  text-decoration: none;
}
.h3 {
  /* font-weight: bold; */
  font-size: 20px;
  margin-bottom: 0px;
  text-decoration: none;
}
.h4 {
  font-size: 16px;
  margin-bottom: 0px;
  text-decoration: none;
  font-weight: bold;
}
.h5 {
  /* font-weight: bold; */
  font-size: 14px;
  margin-bottom: 0px;
}
.link-mobile {
  color: blue;
  /* font-weight: bold; */
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0px;
}
.text-long-cus {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navHeader20500 {
  font-size: 20px;
  font-weight: 500;
  color: #222222;
}

.titleCard14400 {
  font-size: 14px;
  font-weight: 400;
  color: #636E88;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.titleCardNew14400 {
  font-size: 14px;
  font-weight: 400;
  color: #636E88;
  text-align: left;
  display: flex;
  align-items: center;
}

.totalCard28500 {
  font-size: 28px;
  font-weight: 500;
  color: #222222;
}

.fontInput12400{
  font-size: 12px;
}

.content-dynamic-scrolls {
  overflow: auto;
  flex-grow: 0;
}

/* WebKit-based scrollbar customization for this specific class */
.content-dynamic-scrolls::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.content-dynamic-scrolls::-webkit-scrollbar-track {
  background: #e0e0e0; /* Light background for the track */
  border-radius: 5px;
}

.content-dynamic-scrolls::-webkit-scrollbar-thumb {
  background: #888; /* Gray thumb */
  border-radius: 5px;
}

.content-dynamic-scrolls::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker on hover */
}

/* Optional: Cross-browser scrollbar styles for this class */
.content-dynamic-scrolls {
  scrollbar-width: none; /* For Firefox */
  scrollbar-color: #888 #e0e0e0; /* Thumb and track colors */
}

.copy-delete {
  position: absolute;
  top: 18px;
  background: #FDECF1;
  text-align: center;
  z-index: 11110;
  width: 114px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.zoom-cus{
  right: -8px;
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
}
.cus-svg svg {
  width: 21px;
  height: 21px;
}

.cus-selecte-svg svg {
  width: 21px;
  height: 21px;
}

.Collapse-Panel-cus {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  right: 0px;
  padding-left: 35px;
}

.content-draggable-cursor {
  cursor: text !important;
}

.Collapse-cus .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
  padding: 5px 6px;
}

.Collapse-cus .ant-collapse-item .ant-collapse-header{
  padding-left: 10px;
}
