.section {
    padding-top: 35px;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

.section-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 4px #0000000D;
    border-radius: 10px;
}

.section-column {
    column-count: 1;
    padding: 15px 25px;
    column-gap: 100px;
}

@media (min-width: 768px) {
    .section-column {
        column-count: 2;
    }

    .section-title {
        font-size: 22px;
    }
}

.update-description {
    margin: 0.5em;
    width: 50%;
}

#submit-buttons {
    margin: 0 30%;
}

.staff-detail {
    position: relative;
    display: flex;
    align-items: center;
}

.staff-detail-icon {
    margin-right: 0.3em;
    display: block;
}

.staff-detail .ant-typography-secondary {
    font-weight: bold;
    color: var(--bs-modal-color);
}

.staff-detail input {
    width: 100%;
}

.staff-introduciton {
    border-bottom: 1px solid rgba(210, 207, 207, 0.30);
    display: inline-block;
    width: 100%;
}

.staff-textarea-label {
    padding: 8px 0;
}

.MuiInput-root:before {
    border-bottom: 1px solid rgba(210, 207, 207, 0.30)  !important;
}

.select-tag {
    border-bottom: 1px solid rgba(210, 207, 207, 0.30);
    display: inline-block;
    width: 100%;
}

.ant-select-item-option-content {
    white-space: break-spaces !important;
    word-break: break-word !important;
}
.inputCustomPho{
    height: 39px;
    width: 82.1%;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    padding-left: 12px;
}

.inputCustomPho:focus {
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
    background-color: #ffffff;
}
.selectCus .ant-select-selector{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.input-error-cus{
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}
.newDetail {
    max-width: 50%;
    padding-top: 1.5rem;
}
.userTitle{
    font-weight: 600;
    font-size: 18px;
}

.userLabel {
    color: #636E88;
    font-size: 14px;
}
.userValue {
    color: #222222;
    font-size: 14px;
}
.custom-modal .ant-modal-content .ant-modal-footer{
    text-align: center;
}

.custom-modal .ant-modal-content .ant-modal-header .ant-modal-title{
    font-size: 18px;
    color: #222222;
}