:where(.cusStyle).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #e72158;
    font-weight: 400;
    font-size: 14px;
    text-shadow: 0 0 0.25px currentcolor;
}

/* Remove the default focus/active styles */
:where(.cusStyle).ant-tabs .ant-tabs-ink-bar {
    background-color: #F8E2E8 !important; /* Set this to match your border color */
    border-bottom: 5px solid #F8E2E8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Optional: Override the default focus ring if needed */
:where(.cusStyle).ant-tabs .ant-tabs-tab-btn:focus {
    outline: none !important; /* Removes default focus outline if needed */
}

/* Hover effect for tab items */
:where(.cusStyle).ant-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #e72158 !important; /* Text color on hover */
}
.cusStyle .ant-tabs-nav .ant-tabs-nav-wrap{
    padding-left: 25px;
}

:where(.cusStyle).ant-tabs .ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: 400;
    color: #636E88;
}
