.switch_language_body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  margin-right: 20px;
  margin-bottom: 0px;
  padding: 0;
}

.switch_language {
  font-size: 18px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.switch_language_body a {
  text-decoration: none;
}